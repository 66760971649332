"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var content = [
    {
        title: 'General',
        content: [
            {
                question: 'Who are Deko?',
                answer: (React.createElement("p", { className: 'answer' },
                    "Deko is a trading name of NewDay Technology Ltd, registered in England and Wales (company number 08812602). NewDay Technology Ltd is an Appointed Representative of NewDay Cards Ltd for the purposes of providing credit broking services. NewDay Cards Ltd is authorised and regulated by the Financial Conduct Authority (FRN 682417).",
                    React.createElement("br", null),
                    "Our technology enables you to apply for finance for goods and services from one of our lending partners. For more information, please visit",
                    ' ',
                    React.createElement("a", { href: 'https://www.dekopay.com/', target: '_blank' }, "www.dekopay.com"),
                    ".")),
            },
            {
                question: 'How does Deko work?',
                answer: (React.createElement("p", { className: 'answer' }, "When you apply for credit online, Deko securely captures, formats and transmits your application to the lender. If your application is approved, we keep the retailer and the lender updated as your order is processed and delivered.")),
            },
            {
                question: 'How do I contact Deko support?',
                answer: (React.createElement("div", null,
                    React.createElement("p", { className: 'answer' },
                        "If you have a question regarding your finance application through Deko, you can contact our support team on ",
                        React.createElement("a", { href: 'mailto:support@dekopay.com' }, "support@dekopay.com"),
                        " or ",
                        React.createElement("a", { href: 'tel:08002945891', rel: 'noopener', style: { 'color': '#666666' } }, "0800 294 5891"),
                        "."),
                    React.createElement("p", null,
                        React.createElement("strong", null, "Monday to Friday")),
                    React.createElement("p", null, "8am-8pm"),
                    React.createElement("p", null,
                        React.createElement("strong", null, "Saturday")),
                    React.createElement("p", null, "Closed - email only"),
                    React.createElement("p", null,
                        React.createElement("strong", null, "Sunday and Public Holidays")),
                    React.createElement("p", null, "Closed - email only"))),
            },
            {
                question: 'How do I apply for credit?',
                answer: (React.createElement("div", null,
                    React.createElement("p", { className: 'answer' }, "Simply complete our online credit application form and we will then send your details to the lender for a quick decision. You will need to carefully read the credit agreement and related information. If you are happy to proceed, you will be asked to e-sign your agreement online."),
                    React.createElement("p", null, "If your application is approved, you will be able to pay the deposit (where applicable) online using a credit or debit card. Once your agreed deposit has been paid, or if there is no deposit due to be paid, we\u2019ll authorise the retailer to proceed with your order."))),
            },
            {
                question: 'How do I ask a question about the Credit Agreement?',
                answer: (React.createElement("p", { className: 'answer' }, "If you have a question, if there is anything you do not understand or you require any further information about your credit agreement, please contact your lender.")),
            },
            {
                question: 'How long does it take to apply online?',
                answer: (React.createElement("p", { className: 'answer' }, "Our online application form typically takes less than 3 minutes to complete. Your application is then instantly sent to the lender and they will typically respond with their decision in around 10 seconds. Some applications may take longer to process. If for any reason we can't give you an instant decision, we'll send you an email the moment the lender makes a decision")),
            },
            {
                question: "I've been approved for credit and paid the deposit. What happens next?",
                answer: (React.createElement("p", { className: 'answer' }, "We will authorise the retailer to process your order. The retailer will then notify us when they have delivered your order in full. The lender will then write to you to confirm the repayment amounts and the schedule of repayments.")),
            },
            {
                question: 'What are the requirements for finance?',
                answer: (React.createElement("div", null,
                    React.createElement("p", null, "To be eligible to apply for credit you need to meet our lenders eligibility criteria:"),
                    React.createElement("ul", { className: 'answer' },
                        React.createElement("li", null, "You must be 18 years old or over."),
                        React.createElement("li", null, "In regular full or part time employment (minimum 16 hours per week, unless you are retired or a houseperson with a spouse in full time employment)."),
                        React.createElement("li", null, "Be a permanent UK resident and able to supply a UK address history for the last 3 years."),
                        React.createElement("li", null, "You must have a UK bank account capable of accepting Direct Debits."),
                        React.createElement("li", null, "No adverse credit history (no CCJ's, IVA's or bankruptcies)."),
                        React.createElement("li", null, "The goods must be delivered to your home address."),
                        React.createElement("li", null, "The deposit must be paid using a credit or debit card.")))),
            },
            {
                question: 'Can I apply for credit over the phone or in person?',
                answer: (React.createElement("p", { className: 'answer' }, "Please contact your retailer who will advise you if they can arrange credit over the phone or in person.")),
            },
            {
                question: 'Where do I obtain a VAT invoice?',
                answer: (React.createElement("p", { className: 'answer' }, "We are not the business you have purchased from and therefore cannot supply a VAT receipt. Please contact the retailer you have purchased from and they should be able to assist you with your query.")),
            },
            {
                question: 'Email communication problems?',
                answer: (React.createElement("div", null,
                    React.createElement("p", { className: 'answer' }, "With your permission, we will email you updates to the status of your application and to confirm when your application is complete. We will send these emails to the email address you provide on the credit application form so please ensure you enter your address carefully. To ensure that you do not miss any important communication please add the following email addresses to your address book: donotreply@dekopay.com."),
                    React.createElement("p", null, "If you have not received any email from us, please check your spam/junk mail filter."))),
            },
            {
                question: 'Why was I declined?',
                answer: (React.createElement("div", null,
                    React.createElement("p", { className: 'answer' }, "Unfortunately, we are not given the reason why a customer is declined for credit. The authorisation process is carried out by the lender and Deko has no part in the decision. The decline message is sent to us by the lender with no explanation or justification. Sometimes, the reason for a decline is that incorrect information has been entered in the credit application form. Please ensure you carefully enter all required information."),
                    React.createElement("p", null, "If your application is declined, you can contact the lender to find out why."))),
            },
            {
                question: 'Can I withdraw from my credit agreement if I change my mind?',
                answer: (React.createElement("div", null,
                    React.createElement("p", { className: 'answer' }, "You have the right under section 66A of the Consumer Credit Act 1974 to withdraw from the agreement without giving any reason within 14 days from the day after we confirm that the credit agreement is made or, if later, the date on which your lender signs the credit agreement."),
                    React.createElement("p", null, "If you wish to withdraw you must give the lender notice by telephone, email or post. The name of your lender will be clearly shown on your credit agreement. Please note that if you do give notice of withdrawal, you must repay the whole of the credit without delay and in any event by no later than 30 days after giving notice of withdrawal. You will also have to pay interest accrued from the date the agreement was made until the date you repay it."),
                    React.createElement("p", null, "Your lender will tell you about the payment methods available, either in your online account or in their communications with you. If you withdraw from your agreement you will also have to pay for your goods with an alternative payment method."))),
            },
            {
                question: 'Will the lender perform a credit check?',
                answer: (React.createElement("p", { className: 'answer' }, "Yes. A credit check forms part of the automated decision-making process and is part of a lender\u2019s responsible lending policy.")),
            },
            {
                question: 'What is responsible lending?',
                answer: (React.createElement("div", null,
                    React.createElement("p", { className: 'answer' }, "All lenders are required to lend responsibly. A responsible lender will perform checks and review available information to establish your creditworthiness, and whether you will be able to afford the loan repayments without having to miss other payments or commitments."),
                    React.createElement("p", null, "Lenders endeavour to make their decisions as transparent and as objective as possible. Credit scoring is one of the decision-making tools they use: a technique used to assess the probability that customers can and will meet their financial commitments. Credit scoring uses data received from credit reference agencies. In addition to their affordability assessment, lenders will use this information to determine if a customer is an acceptable credit risk - if there is a higher likelihood that a customer will default on their repayments, the lender will consider them a higher risk and may need to charge a higher interest rate, or decline the application. Responsible lending should be seen as a complement to customers' own obligations to borrow responsibly."))),
            },
            {
                question: 'What is responsible borrowing?',
                answer: (React.createElement("div", null,
                    React.createElement("p", { className: 'answer' }, "Borrowing responsibly can help you to avoid unaffordable debt. It is important that you don't borrow more money than you can afford to pay back, and that you don't become dependent on finance to pay for your essentials every month."),
                    React.createElement("p", null, "Missing payments or making late payments will result in fees and additional interest on your loan, which means you will need to pay back more overall. Late or missed payments can also affect your credit file and your ability to obtain credit in the future."))),
            },
            {
                question: 'What if I have bad credit?',
                answer: (React.createElement("p", { className: 'answer' }, "The lender uses an automated credit check as part of their decision-making process. If you have an adverse credit record it may affect their decision. To give yourself the best chance of acceptance, please ensure you complete the credit application form correctly. Please don't apply for credit unless you're sure you will be able to comfortably repay it.")),
            },
            {
                question: 'When I apply for credit I get an error. What should I do?',
                answer: (React.createElement("p", { className: 'answer' },
                    "When you submit your credit application, we check the information you provide is valid and complete before securely transmitting it to the lender. We develop and test our software very thoroughly but sometimes errors can still occur. To report an error, please email ",
                    React.createElement("a", { href: 'mailto:customersupport@dekopay.com' }, "customersupport@dekopay.com"),
                    ' ',
                    "giving a concise description of the problem and attaching a screenshot if possible. Please only send a screenshot if none of your personal data is visible on the screen.")),
            },
            {
                question: 'What if I have a complaint about the lender?',
                answer: (React.createElement("p", { className: 'answer' }, "If you would like to make a complaint, in the first instance please contact your lender by email or telephone (see below). The name of your lender will be clearly shown on your credit agreement. Their customer service team will tell you about their complaints policy and try to resolve any problems.")),
            },
            {
                question: 'I have not yet received my goods/services',
                answer: (React.createElement("p", { className: 'answer' },
                    "Deko is the authorised finance gateway for the site you made a purchase from and not the owner of the site. Please contact the retailer who owns the site you have purchased from and they will be able to assist you with your query. If you have already contacted your retailer and you have not received a response after 48 hours, please email ",
                    React.createElement("a", { href: 'mailto:customersupport@dekopay.com' }, "customersupport@dekopay.com"),
                    ' ',
                    "to open a support request.")),
            },
            {
                question: 'I have a question, problem or need to return my product',
                answer: (React.createElement("p", { className: 'answer' },
                    "Deko is the authorised finance gateway for the site you made a purchase from and not the owner of the site. Please contact the retailer who owns the site you have purchased from and they will be able to assist you with your query. If you have already contacted your retailer and you have not received a response after 48 hours, please email ",
                    React.createElement("a", { href: 'mailto:customersupport@dekopay.com' }, "customersupport@dekopay.com"),
                    ' ',
                    "to open a support request.")),
            },
            {
                question: 'I am having trouble contacting my retailer',
                answer: (React.createElement("p", { className: 'answer' },
                    "Please review your payment receipt sent by the retailer immediately after your purchase. When contacting the retailer, please quote the reference shown on your email receipt. If you are still having problems contacting the retailer and you have not received a response after 48 hours, please email ",
                    React.createElement("a", { href: 'mailto:customersupport@dekopay.com' }, "customersupport@dekopay.com"),
                    ' ',
                    "to open a support request.")),
            },
            {
                question: 'What if my enquiry is not answered by the FAQs?',
                answer: (React.createElement("p", { className: 'answer' },
                    "Answers to the most frequently asked questions are detailed in this section. If your enquiry cannot be answered by the FAQ please",
                    ' ',
                    React.createElement("a", { href: 'https://www.dekopay.com/contact', target: '_blank' }, "contact us"),
                    ".")),
            },
            {
                question: 'Why am I under review?',
                answer: (React.createElement("p", { className: 'answer' }, "Review is a standard part of our application process, meaning our lender may require some additional information for example copy of payslips and they are likely to be reaching out to you shortly, so please provide this additional information as soon as possible. Additional information must be provided within 72 hours, otherwise your finance application may be cancelled and you will need to reapply for finance in order to complete your purchase.")),
            },
        ],
    },
    {
        title: 'Payment',
        content: [
            {
                question: 'Do I need a bank account?',
                answer: (React.createElement("p", { className: 'answer' }, "Yes. You must have a UK bank account capable of accepting Direct Debits.")),
            },
            {
                question: 'Do I need to pay a deposit?',
                answer: (React.createElement("p", { className: 'answer' }, "If your credit application is accepted and you have selected to pay a deposit, you will be required to pay a deposit online, using a credit or debit card. The amount of the deposit is shown in the credit summary presented to you and on the credit agreement itself.")),
            },
            {
                question: 'Can I pay my agreement off early?',
                answer: (React.createElement("p", { className: 'answer' }, "You have the right to repay all or part of the credit early at any time.")),
            },
            {
                question: 'How much does the loan cost?',
                answer: (React.createElement("p", { className: 'answer' }, "The total cost of the loan is shown clearly on the finance agreement.")),
            },
            {
                question: 'Are there any other charges?',
                answer: (React.createElement("div", null,
                    React.createElement("p", { className: 'answer' }, "If you fail to pay any amount you owe under the Credit Agreement by the date it is due, the lender may charge you interest on that amount until you pay it. They may also levy charges for:"),
                    React.createElement("ul", null,
                        React.createElement("li", null, "processing any payment made other than by Direct Debit, sending arrears letters, reminders or documents to which you are not entitled"),
                        React.createElement("li", null, "arrears telephone calls"),
                        React.createElement("li", null, "tracing you if you move address without notifying them"),
                        React.createElement("li", null, "cheques, standing orders or Direct Debits which are dishonoured, stopped or not paid by you")),
                    React.createElement("p", null, "Please refer to the credit agreement for full details."))),
            },
            {
                question: 'How do I repay the loan?',
                answer: (React.createElement("p", { className: 'answer' }, "You will be advised on the term of your loan when you make your application and how many monthly payments that will be required. The lender will write to you to confirm the date that your repayments will commence. The first payment is typically due 30 days following the date of delivery of your order.")),
            },
            {
                question: 'What APR will I be charged?',
                answer: (React.createElement("p", { className: 'answer' }, "The APR is shown clearly on the credit summary (in the top right hand corner of the screen) throughout the credit application process. It is also shown clearly on the credit agreement itself. The APR for Interest free credit is 0%")),
            },
            {
                question: "What if I'm already in debt?",
                answer: (React.createElement("div", null,
                    React.createElement("p", { className: 'answer' }, "If you are already experiencing problems meeting existing financial commitments, please do not apply for credit. Further borrowing will only add to your commitments and repayments will commence within a month. If you need help with your debts, we recommend you talk to one of these free debt advice services:"),
                    React.createElement("ul", null,
                        React.createElement("li", null,
                            React.createElement("a", { href: 'https://www.moneyhelper.org.uk/', target: '_blank' }, "Money Helper")),
                        React.createElement("li", null,
                            React.createElement("a", { href: 'https://www.citizensadvice.org.uk', target: '_blank' }, "Citizens Advice Bureau")),
                        React.createElement("li", null, "StepChange Debt Charity"),
                        React.createElement("li", null, "Payplan"),
                        React.createElement("li", null, "National Debtline"),
                        React.createElement("li", null, "Christians Against Poverty")))),
            },
        ],
    },
    {
        title: 'Security',
        content: [
            {
                question: 'Is it safe to apply online? Will my details be secure?',
                answer: (React.createElement("p", { className: 'answer' }, "Yes. It is extremely safe to apply online. Your personal information is encrypted before transmission to ensure it cannot be viewed by any unauthorised third party. The Deko finance gateway uses proven techniques to ensure the security and integrity of sensitive data. Our public web servers are certified by Thawte, a leading Certificate Authority, ensuring that both the consumer and the retailer can have confidence that nobody can impersonate Deko to obtain confidential information. Data storage on Deko systems and the communication between Deko and our partner lenders, is regularly audited to the highest standards to ensure a secure transaction environment. This includes specific regular third-party testing for vulnerabilities and reviews of application security and access control procedures.")),
            },
            {
                question: 'What personal information do you need?',
                answer: (React.createElement("p", { className: 'answer' }, "We require personal details like your name, address, phone numbers and income. Plus your bank account and credit or debit card details. We need this information so we can be sure who's applying in order for the lender to perform a credit check. To give yourself the best chance of approval, you should always ensure the information you give us is accurate and honest. For full details about the collection and potential uses of your personal details please refer to our Privacy Policy")),
            },
            {
                question: 'What will happen with my personal information?',
                answer: (React.createElement("div", null,
                    React.createElement("p", { className: 'answer' }, "We will need to share the personal information you provide with the lender in order for them to make a decision about whether to offer you finance. Your personal information is encrypted before transmission to ensure it cannot be viewed by any unauthorised third party."),
                    React.createElement("p", null, "The Deko finance gateway uses proven techniques to ensure the security and integrity of sensitive data. Our public web servers are certified by Thawte, a leading Certificate Authority, ensuring that both the consumer and the retailer can have confidence that nobody can impersonate Deko to obtain confidential information. Data storage on Deko systems and the communication between Deko and our partner lenders, is regularly audited to the highest standards to ensure a secure transaction environment. This includes specific regular third-party testing for vulnerabilities and reviews of application security and access control procedures."),
                    React.createElement("p", null, "You can find out more about how we use and process your personal information in our Privacy Policy."))),
            },
            {
                question: 'Do I have to provide ID?',
                answer: (React.createElement("p", { className: 'answer' }, "Please note that for security measures we may require a proof of ID after your application has been approved and your deposit has been paid. This step is to verify your transaction, and a check will be done on any ID provided. If your ID fails the check or you are unable to provide a valid proof of ID the retailer will be in contact with you. Should you need to provide ID you will be notified via email from Deko or your lender.")),
            },
            {
                question: 'Is the application confidential?',
                answer: (React.createElement("p", { className: 'answer' }, "Yes. Your credit application is treated in the strictest confidence. We will not contact your employers, bank or any other third party.")),
            },
        ],
    },
];
exports.default = content;
