"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_1 = require("react-router");
var ApplicationLoader_1 = require("../common/pages/ApplicationLoader");
var SecurityStatementPage_1 = require("./pages/SecurityStatementPage");
var TermsOfServicePage_1 = require("./pages/TermsOfServicePage");
var FAQPage_1 = require("./pages/FAQPage");
var ErrorPage_1 = require("./pages/ErrorPage");
var NotFoundPage_1 = require("./pages/NotFoundPage");
var DeclinePage_1 = require("./pages/application/DeclinePage");
var CompletePage_1 = require("./pages/application/CompletePage");
var AmendmentDeclinedPage_1 = require("./pages/application/AmendmentDeclinedPage");
var ResumeApplicationPage_1 = require("../common/pages/ResumeApplicationPage");
var Loader_1 = require("../common/components/Loader");
var CancelPage_1 = require("./pages/application/CancelPage");
var ErrorBoundary_1 = require("./components/ErrorBoundary");
var FulfilPage_1 = require("./pages/application/FulfilPage");
var CompleteFulfilledPage_1 = require("./pages/application/CompleteFulfilledPage");
var CompleteVerifiedPage_1 = require("./pages/application/CompleteVerifiedPage");
var TelephoneApplicationEndPage_1 = require("./pages/TelephoneApplicationEndPage");
var inversify_types_1 = require("../common/inversify.types");
var inversify_container_1 = require("../common/inversify.container");
var DepositProcessPage_1 = require("../common/pages/deposit/DepositProcessPage");
var ExhaustedIdUploadAttemptsPage_1 = require("./pages/ExhaustedIdUploadAttemptsPage");
var WhatIsACreditSearchPageKlarna_1 = require("./pages/what-is-a-credit-search/WhatIsACreditSearchPageKlarna");
var WhatIsACreditSearchPageBraemar_1 = require("./pages/what-is-a-credit-search/WhatIsACreditSearchPageBraemar");
var WhatIsACreditSearchPageDeko_1 = require("./pages/what-is-a-credit-search/WhatIsACreditSearchPageDeko");
var DynamicFormScripts = inversify_container_1.default.get(inversify_types_1.default.DynamicFormScripts);
var ApplicationRoutes = function (_a) {
    var match = _a.match;
    return (React.createElement(ApplicationLoader_1.default, { token: match.params.token, loader: React.createElement(Loader_1.default, null), branding_key: 'magi_app_form' },
        React.createElement(ErrorBoundary_1.default, null,
            React.createElement(DynamicFormScripts, null),
            React.createElement(react_router_1.Switch, null,
                React.createElement(react_router_1.Route, { path: match.url + "/telephone", component: ErrorPage_1.default }),
                React.createElement(react_router_1.Route, { path: match.url + "/telephone-decision", component: TelephoneApplicationEndPage_1.default }),
                React.createElement(react_router_1.Route, { path: match.url + "/telephone-alternate-finance", component: ErrorPage_1.default }),
                React.createElement(react_router_1.Route, { path: match.url + "/(resume|resume-instore)", component: ResumeApplicationPage_1.default }),
                React.createElement(react_router_1.Route, { path: match.url + "/prequalification", component: ErrorPage_1.default }),
                React.createElement(react_router_1.Route, { path: match.url + "/apply", component: ErrorPage_1.default }),
                React.createElement(react_router_1.Route, { path: match.url + "/deposit/process", component: DepositProcessPage_1.default }),
                React.createElement(react_router_1.Route, { path: match.url + "/amendment/confirmation", component: ErrorPage_1.default }),
                React.createElement(react_router_1.Route, { path: match.url + "/amendment/declined", component: AmendmentDeclinedPage_1.default }),
                React.createElement(react_router_1.Route, { path: match.url + "/cancel", component: CancelPage_1.default }),
                React.createElement(react_router_1.Route, { path: match.url + "/alternative-finance", exact: true, component: ErrorPage_1.default }),
                React.createElement(react_router_1.Route, { path: match.url + "/documents", exact: true, component: ErrorPage_1.default }),
                React.createElement(react_router_1.Route, { path: match.url + "/documents/sign", component: ErrorPage_1.default }),
                React.createElement(react_router_1.Route, { path: match.url + "/documents/instructions", exact: true, component: ErrorPage_1.default }),
                React.createElement(react_router_1.Route, { path: match.url + "/deposit", component: ErrorPage_1.default }),
                React.createElement(react_router_1.Route, { path: match.url + "/complete", component: CompletePage_1.default }),
                React.createElement(react_router_1.Route, { path: match.url + "/decline", component: DeclinePage_1.default }),
                React.createElement(react_router_1.Route, { path: match.url + "/refer", component: ErrorPage_1.default }),
                React.createElement(react_router_1.Route, { path: match.url + "/awaiting-decision", component: ErrorPage_1.default }),
                React.createElement(react_router_1.Route, { path: match.url + "/id-upload", exact: true, component: ErrorPage_1.default }),
                React.createElement(react_router_1.Route, { path: match.url + "/id-upload/success", component: ErrorPage_1.default }),
                React.createElement(react_router_1.Route, { path: match.url + "/instore-id-upload/", component: ErrorPage_1.default }),
                React.createElement(react_router_1.Route, { path: match.url + "/fulfil", component: FulfilPage_1.default }),
                React.createElement(react_router_1.Route, { path: match.url + "/complete-fulfilled", component: CompleteFulfilledPage_1.default }),
                React.createElement(react_router_1.Route, { path: match.url + "/complete-verified", component: CompleteVerifiedPage_1.default }),
                React.createElement(react_router_1.Route, { component: NotFoundPage_1.default })))));
};
exports.default = (React.createElement(react_router_1.Switch, null,
    React.createElement(react_router_1.Route, { path: '/security-statement', component: SecurityStatementPage_1.default }),
    React.createElement(react_router_1.Route, { path: '/terms-of-service', component: TermsOfServicePage_1.default }),
    React.createElement(react_router_1.Route, { path: '/faq', component: FAQPage_1.default }),
    React.createElement(react_router_1.Route, { path: '/problem', component: ErrorPage_1.default }),
    React.createElement(react_router_1.Route, { path: '/exhausted-id-upload-attempts', component: ExhaustedIdUploadAttemptsPage_1.default }),
    React.createElement(react_router_1.Route, { path: '/expired', component: ErrorPage_1.default }),
    React.createElement(react_router_1.Route, { path: '/what-is-a-credit-search-klarna', component: WhatIsACreditSearchPageKlarna_1.default }),
    React.createElement(react_router_1.Route, { path: '/what-is-a-credit-search-braemar', component: WhatIsACreditSearchPageBraemar_1.default }),
    React.createElement(react_router_1.Route, { path: '/what-is-a-credit-search', component: WhatIsACreditSearchPageDeko_1.default }),
    React.createElement(react_router_1.Route, { path: '/:token', component: ApplicationRoutes }),
    React.createElement(react_router_1.Route, { component: NotFoundPage_1.default })));
